<template>
	<div class="invite">
		<div v-for="item in table_data" :key="item.id" class="boxc">
			<div class="noe" v-if="item.status == 1 && item.money >= 100">
				<div class="menkan">
					<p class="shib">{{ item.money }}元</p>
					<p class="wum">无门槛</p>
				</div>
				<div class="yaosan">
					<p>{{ item.title }}优惠券</p>
					<p class="dik">购买设备时可进行金额抵扣</p>
					<p class="souy">解释权归江苏星芒所有</p>
				</div>
				<div class="shiyong expired" v-if="item.expired">已过期</div>
				<div class="shiyong" v-else @click="buyEquipment">立即使用</div>
			</div>

			<div class="noe tow" v-if="item.status == 1 && item.money < 100">
				<div class="menkan">
					<p class="shib">{{ item.money }}元</p>
					<p class="wum">无门槛</p>
				</div>
				<div class="yaosan">
					<p>{{ item.title }}优惠券</p>
					<p class="dik">购买设备时可进行金额抵扣</p>
					<p class="souy">解释权归江苏星芒所有</p>
				</div>
				<div class="shiyong" @click="buyEquipment">立即使用</div>
			</div>
			<div class="noe sher" v-if="item.status == 2">
				<div class="menkan">
					<p class="shib">{{ item.money }}元</p>
					<p class="wum">无门槛</p>
				</div>
				<div class="yaosan">
					<p>{{ item.title }}优惠券</p>
					<p class="dik">购买设备时可进行金额抵扣</p>
					<p class="souy">解释权归江苏星芒所有</p>
				</div>
				<div class="shiyi">已使用</div>
			</div>
		</div>
		<div class="kox" v-if="table_data.length == 0">
			<div class="wuyu"></div>
			<p class="konga">空空如也</p>
			<p class="konga cca">暂无优惠券</p>
		</div>
	</div>
</template>

<script>
import { coupon_coupen } from "@/api/const_manage";

import { copy } from "iclipboard";
export default {
	name: "coupon_fee",
	data() {
		return {
			table_data: [],
		};
	},
	mounted() {
		this.get_data();
	},
	methods: {
		// 购买设备
		buyEquipment() {
			// localStorage.isLogin = 2
			this.$router.push({ name: "manage_buyequipment" });
		},
		copyClick: function () {
			if (copy(this.link)) {
				this.$message.success("复制成功");
			} else {
				this.$message.error("复制失败");
			}
		},

		async get_data() {
			let { data } = await coupon_coupen({ pagesize: 100 });
			if (data.code == 200) {
				let list = data.data.list.map(item => {
					if (
						new Date(item.expire_time).valueOf() <
						new Date().valueOf()
					) {
						// 已过期
						item.expired = true;
					}
					return item;
				});
				this.table_data = list;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.kox {
	width: 1200px;
}
.konga {
	// width: 64px;
	text-align: center;
	height: 22px;
	font-size: 16px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #2c354b;
	line-height: 22px;
}
.cca {
	// width: 65px;
	text-align: center;
	height: 18px;
	font-size: 13px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ababab;
	line-height: 18px;
}
.wuyu {
	background: url("../../../assets/img/fee/aa.png");
	width: 280px;
	height: 142px;
	margin: auto;
	margin-top: 117px;
	margin-left: 465px;
}
.boxc {
	display: flex;
	flex-wrap: wrap;
}
.equipment .invite {
	flex-wrap: wrap;
	display: flex;
	width: 1200px;
	// height: 880px;
	background: #ffffff;
	margin: auto;
	flex: none;
	.noe {
		width: 478px;
		height: 168px;
		background: url("../../../assets/img/fee/100.png");
		overflow: hidden;
		margin-top: 22px;
		margin-left: 40px;
		display: flex;
	}
	.sher {
		background: url("../../../assets/img/fee/00.png");
	}
	.tow {
		background: url("../../../assets/img/fee/18.png");
	}
}
.menkan {
	width: 124px;
	height: 127px;
	margin-top: 19px;
	margin-left: 21px;
	// border: 1px solid red;
	overflow: hidden;
	p {
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 18px;
		text-align: center;
	}
	.shib {
		font-size: 25px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ffffff;
		line-height: 46px;
		margin-top: 20px;
		margin-bottom: 6px;
	}
}
.yaosan {
	width: 156px;
	height: 127px;
	// border: 1px solid springgreen;
	margin-top: 19px;
	margin-left: 30px;
	padding-top: 21px;
	p {
		// width: 90px;
		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #2c354b;
		line-height: 25px;
		margin-bottom: 8px;
	}
	.dik {
		width: 156px;
		height: 18px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #878fa7;
		line-height: 18px;
	}
	.souy {
		width: 120px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #caced7;
		line-height: 17px;
		margin-top: 20px;
	}
}
.shiyong {
	width: 88px;
	height: 30px;
	background: #ff4d4d linear-gradient(180deg, #ff9e14 0%, #ff613b 100%);
	border-radius: 15px;
	font-size: 13px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 30px;
	text-align: center;
	margin-top: 67px;
	margin-left: 30px;
	cursor: pointer;
	&.expired {
		filter: grayscale(100%);
		cursor: not-allowed;
	}
}
.shiyi {
	width: 88px;
	height: 30px;

	background: linear-gradient(132deg, #dee0e4 0%, #cdcdd7 100%);
	border-radius: 15px;
	font-size: 13px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 30px;
	text-align: center;
	margin-top: 67px;
	margin-left: 30px;
	// cursor: pointer;
}
</style>
